import { get } from 'api/request';

function deprecated_getDataByProperty(url, value, displayText) {
  return get(url).then((data) => {
    if (data?.error) {
      return data;
    }
    return data.map((tableRow) => {
      if (!tableRow[value]) console.debug('mapping failed for ' + url);
      return {
        text: tableRow[displayText] ?? tableRow[value],
        value: tableRow[value],
      };
    });
  });
}

function getData(url) {
  return get(url).then((data) => {
    if (data?.error) return data;
    return data?.map?.((o) => ({
      text: o?.Title,
      value: o?.Value,
    }));
  });
}

function isThreeDigits(string = '') {
  const isValid = string.replace('_', '')?.length === 3;
  return isValid;
}


// country is from Form > countryInfo > name
export const functionsByCountry = {
  //@todo switch to china/hkcb
  CN: {
    STATE: (args) => {
      return getData(
        `/api/enrollment/states?country=china&localeId=${args.localeId}`,
      );
    },
    CITY: (args) => {
      const state = args.default;
      if (!state) return Promise.resolve([]);
      return getData(
        `/api/enrollment/cities?country=china&state=${state}&localeId=${args.localeId}`,
      );
    },
    COUNTY: (args) => {
      const province = args.default.STATE;
      const city = args.default.CITY;
      if (!city) return Promise.resolve([]);
      return getData(
        `/api/enrollment/counties?country=china&state=${province}&city=${city}&localeId=${args.localeId}`,
      );
    },
  },
  TW: {
    STATE: (args) => {
      const postalCode = args.default;
      if (!isThreeDigits(postalCode)) return Promise.resolve([]);

      return getData(
        `/api/enrollment/states?country=taiwan&postalCode=${postalCode}&localeId=${args.localeId}`,
      );
    },
    CITY: (args) => {
      const postalCode = args.default.ZIP;
      const state = args.default.STATE;
      if (!isThreeDigits(postalCode)) return Promise.resolve([]);

      return getData(
        `/api/enrollment/cities?country=taiwan&postalCode=${postalCode}&state=${state}&localeId=${args.localeId}`,
      );
    },
  },
  HK: {
    STATE: (args) =>
      deprecated_getDataByProperty(
        `/api/getHongKongStates?locale=${args.locale}`,
        'stateName',
        'translatedStateName',
      ),
  },
  MY: {
    STATE: (args) =>
      getData(
        `/api/enrollment/states?country=malaysia&localeId=${args.localeId}`,
      ),
  },
  TH: {
    STATE: (args) =>
      getData(
        `/api/enrollment/states?country=thailand&localeId=${args.localeId}`,
      ),
  },
  KR: {
    NAME_BANK: () => {
      return deprecated_getDataByProperty(
        '/api/getKoreanBankNames',
        'BankNameKorean',
      );
    },
  },
};

export default async function getDropDownData(country, id, args) {
  try {
    if (!functionsByCountry[country]) {
      throw new Error(`NO api calls for dropdown menu in country-${country}`);
    }
    if (!functionsByCountry[country][id]) {
      throw new Error(
        `NO api call for dropdown menu "${id}" in country-${country}`,
      );
    }
    const results = await functionsByCountry[country][id](args);
    return results;
  } catch (message) {
    console.error(`failed to GET - ${id} - ${message}`);
  }
}
