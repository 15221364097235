import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import DropDown from 'components/common/form/FormSelectDropdown';
import api from 'api';

export default function ApiDropDown(props) {
  const {
    id,
    label,
    onFocusElement,
    setOnFocusElement = () => {},
    openCascade,
    onChange: propsOnChange = () => {},
    value,
    apiCallData,
    isValid,
    country,
    field,
  } = props;

  const { locale, localeId } = useRecoilValue(formState);
  const [options, set_options] = useState([]);
  const [active, set_active] = useState(false);

  useEffect(() => {
    // TODO: Change `apiCallData` to be an object through the whole callstack and append locale to it instead of using `default`
    const args = { locale, localeId, default: apiCallData };
    api.dropdowns(country, field || id, args).then((results) => {
      if (results?.error) {
        console.error(results.error);
        set_options([]);
      } else set_options(results);
    });
    return () => {
      set_options([]);
    };
  }, [field, id, apiCallData, country, locale, localeId]);

  useEffect(() => {
    if (options?.length === 1 && value !== options[0].text) {
      onChange(options[0].text);
    }
  }, [options]);

  useEffect(() => {
    if (openCascade && onFocusElement === id) set_active(true);
    else set_active(false);
  }, [onFocusElement]);

  useEffect(() => {
    isValid(!!value);
  }, [value]);

  function onChange(text) {
    propsOnChange(id, text);
    set_active(false);
    if (setOnFocusElement) setOnFocusElement();
  }
  const newProps = {
    ...props,
    options,
    setActive: set_active,
    active,
    selectId: id,
    labelText: label,
    onChange,
    value,
    country,
  };

  return <DropDown {...newProps} />;
}
