import { useState, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import useInput from 'hooks/useInput';
import ValidIcon from 'components/common/icons/valid';
import { useRecoilValue } from 'recoil';
import { inputState, formState } from 'state';
import { v2 } from 'components/common/form/formValidationRules';
import { getEntryByLocale } from 'api/contentful';
import Dropdown from '../form/dropdowns/ApiDropdown';
import { findInDictionary } from 'helpers/parse';
import { useRecoilState } from 'recoil';
import {
  getMarketConfigByCountry,
  getMarketConfigByInputProps,
} from 'helpers/marketConfig';
import { Checkbox, Link } from '@newulife/common';
import { FormControlLabel } from '@mui/material';
import { Stack } from '@mui/system';
import localizedData from 'translations/pages/create-account';

export function Identity(props) {
  let { labelRef } = props;
  const inputs = useRecoilValue(inputState);
  const { COMPANY } = inputs;

  if (COMPANY) labelRef = 'BUSINESS_IDENTITY';
  const marketProps = getMarketConfigByInputProps(props);
  const newProps = {
    ...marketProps,
    labelRef,
  };

  return <InputType {...newProps} />;
}

function Password(props) {
  const { isValid } = props;
  const id = 'PASSWORD';
  const { locale } = useRecoilValue(formState);
  const [secure, setSecure] = useState(true);
  const [showRequirements, setshowRequirements] = useState(false);
  const [text, setText] = useState([]);

  useEffect(() => {
    getEntryByLocale('1KsGscO93HK8GFqnX5EbrA', locale).then((res) => {
      const {
        fields: { title, pdfReferences = [] },
      } = res;
      const singleLines = pdfReferences.map(
        ({ fields: { descriptiveTitle } }) => descriptiveTitle,
      );
      if (props) setText([title, ...singleLines]);
    });
  }, [locale]);

  const { handleChange, value } = useInput(id);

  function toggleShowRequirements() {
    if (!showRequirements) setshowRequirements(true);
    if (validate()) {
      setshowRequirements(false);
    }
  }

  function validate() {
    if (checkLength() && hasLowercase() && hasUppercase() && hasNumber())
      return true;
    return false;
  }

  useEffect(() => {
    if (validate()) isValid(true);
    else isValid(false);
  }, [value]);
  function checkLength() {
    return value?.length >= 8;
  }
  function hasUppercase() {
    return value.match('[A-Z]+');
  }
  function hasLowercase() {
    return value.match('[a-z]+');
  }
  function hasNumber() {
    return value.match('[0-9]+');
  }
  const [
    label,
    show,
    hide,
    validateUppercase,
    validateLowercase,
    validateNumber,
    validateCharacters,
  ] = text;

  return (
    <div className="password-wrapper">
      <div className="input-wrapper">
        <input
          type={secure ? 'password' : 'text'}
          id="PASSWORD"
          onChange={(e) => handleChange(e)}
          value={value}
          name="PASSWORD"
          onFocus={(e) => toggleShowRequirements()}
          onBlur={(e) => toggleShowRequirements()}
        />
        <label htmlFor="PASSWORD" aria-label="password">
          {label}
        </label>
        <button
          onClick={(_) => setSecure(!secure)}
          type="button"
          id="passwordShow"
        >
          {secure ? show : hide}
        </button>
      </div>

      <ul className={`validation-list ${showRequirements ? 'active' : ''}`}>
        <Validation
          id="check-length"
          text={validateCharacters}
          validationFunction={checkLength}
        />
        <Validation
          id="check-upper"
          text={validateUppercase}
          validationFunction={hasUppercase}
        />
        <Validation
          id="check-lower"
          text={validateLowercase}
          validationFunction={hasLowercase}
        />
        <Validation
          id="check-number"
          text={validateNumber}
          validationFunction={hasNumber}
        />
      </ul>
    </div>
  );
}

function Validation(props) {
  const { id, text, validationFunction } = props;
  return (
    <li id={id}>
      <ValidIcon isValid={validationFunction()} />
      <p>{text}</p>
    </li>
  );
}

export function BlueSwitch({
  defaultChecked = false,
  id,
  links = [],
  isChecked: setParentIsChecked = () => { },
  label,
  onChange: propsOnChange = () => { },
  hack,
  onOpenModal,
}) {
  const [checked, set_checked] = useState(defaultChecked);

  function handleChange(e) {
    const { checked } = e.target;
    set_checked(checked);
    propsOnChange(checked);
  }

  return (
    <FormControlLabel
      data-testid={`toggle-${id}`}
      sx={{
        alignItems: 'flex-start',
      }}
      control={
        <Checkbox
          className={hack ? 'js-check' : ''}
          id={id}
          name={id}
          aria-label={label}
          onChange={handleChange}
          checked={checked}
        />
      }
      label={
        <Stack gap={1} pt={0.3}>
          {!!label?.length && (
            <label htmlFor={id}>{label}</label>
          )}
          {links?.map(({ text, url }, i) => (
            <Link
              key={`toggle-link-${i}`}
              data-testid="termsAndConditions-pdf"
              target="_blank"
              underline="hover"
              sx={{
                fontWeight: 500,
                display: 'block',
              }}
              href={url}
            >
              {text}
            </Link>
          ))}
        </Stack>
      }
    />
  );
}

export function TextInput(props) {
  const {
    id,
    label,
    isValid: setValidCheckFromProps = () => { }, // optional
    forId: forIdFromProps, // optional
    autoCompleteName = '', // optional
    onChange: propsOnChange = () => { }, // optional
    onBlur: propsOnBlur, // optional
    onBlurArray: propsOnBlurArray, // optional
    value: propsValue = '', // optional
    validationChecks,
    dictionary,
    disabled,
    inputObj = { helperText: '' },
    locale,
    mask,
    required = true,
    hide = false,
    country,
    market,
    forceError,
    dataTestId,
  } = props;

  const forId = forIdFromProps || id;
  const [errorMessageId, set_errorMessageId] = useState();
  const [validityString, set_validityString] = useState('untouched'); // ['untouched', 'dirty', 'valid']

  // validates optional fields with no validation checks immediately
  useEffect(() => {
    checkValueValidity(propsValue);
  }, [propsValue]);

  useEffect(() => {
    setValidCheckFromProps(validityString === 'valid');
  }, [validityString]);

  function handleChange(event) {
    set_validityString('dirty');
    const { value } = event.target;
    propsOnChange(id, value);
  }

  const checkValueValidity = (value = '') => {
    const setError = (error = 'requiredFields') => {
      set_validityString('error');
      set_errorMessageId(error);
    };
    const setValid = () => {
      set_validityString('valid');
      set_errorMessageId();
    };
    if (!value && !required) setValid();
    else if (!value && required && validityString === 'untouched') return
    else if (!value && required) setError();
    else if (value) {
      v2(validationChecks)(value).then((errorMessage) => {
        if (!errorMessage) {
          setValid();
        } else {
          setError(errorMessage);
        }
      });
    } else {
      // shouldn't reach this code
      throw Error(
        'Input validation error',
        '',
        'src/components/common/inputs/Input.jsx',
        277,
      );
    }
  };

  function handleBlur(e) {
    const { value } = e.target;
    checkValueValidity(value);
    if (required && !value) {
      set_validityString('error');
      set_errorMessageId('requiredFields');
    }
    if (propsOnBlur && typeof propsOnBlur === 'function' && !propsOnBlurArray) propsOnBlur(e);
    if (
      propsOnBlur &&
      !propsOnBlurArray &&
      typeof propsOnBlur === 'object' &&
      validityString === 'valid' &&
      value
    ) {
      const [apiCall, errorMessageId] = propsOnBlur;
      apiCall(value, country, market).then((response) => {
        if (!response) {
          set_validityString('error');
          set_errorMessageId(errorMessageId);
        }
      });
    }
    if (validityString === 'valid' && value) {
      propsOnBlurArray?.forEach?.(onBlurFn => {
        const [apiCall, errorMessageId] = onBlurFn;
        if (errorMessageId === 'CUSTOM') {
          apiCall()
        } else {
          apiCall(value, country, market).then((response) => {
            if (!response) {
              set_validityString('error');
              if (errorMessageId) set_errorMessageId(errorMessageId);
            }
          });
        }
      })
    }
  }

  const inputMaskProps = {
    mask,
    id,
    name: autoCompleteName,
    autoComplete: autoCompleteName,
    value: propsValue,
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: disabled,
    lang: locale,
    alwaysShowMask: true,
  };
  if (hide) return null;
  return (
    <div className={`input-wrapper ${validityString}`}>
      <ReactInputMask {...inputMaskProps} data-testid={dataTestId} />
      <InputLabel id={forId} label={label} />
      {errorMessageId || forceError ? (
        <InputErrorMessage
          dictionary={dictionary}
          id={id}
          messageId={errorMessageId || forceError}
        />
      ) : (
        <p className="helper-text">{inputObj.helperText}</p>
      )}
    </div>
  );
}

export function InputLabel({ label: labelFromProps, id, labelId, dictionary }) {
  const label = labelFromProps || findInDictionary(dictionary, labelId);
  if (!label) return null;
  return (
    <label htmlFor={id} aria-label={id}>
      {label}
    </label>
  );
}
export function InputErrorMessage(props) {
  const { id, messageId, dictionary } = props;
  const style = {
    display: 'inline-flex',
    fontFamily: 'Waleray,sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#9a3b26',
    fontWeight: '600',
  };

  const message = findInDictionary(dictionary, messageId) || messageId;
  if (!message) return null;
  return (
    <p
      className="helper-text error"
      style={style}
      data-testid={`${id}-required`}
    >
      {message}
    </p>
  );
}

export default function InputConfig(props) {
  if (!props?.country || !props?.id)
    throw `country-(${props?.country}) and id-(${props?.id}) props are required for inputs`;
  const marketConfig = getMarketConfigByCountry(props.country).inputs[props.id];
  return <InputSelector {...props} {...marketConfig} />;
}
// Selects the Input Component based on id so properties can be added or changed by country or other requirements

function InputSelector(props) {
  const { id } = props;
  switch (id) {
    case 'IDENTITY':
      return <Identity {...props} />;
    case 'PASSWORD':
      return <Password {...props} />;
    default:
      return <DataLink {...props} />;
  }
}

function DataLink(props) {
  const inputs = useRecoilValue(inputState);
  let { formType = 'account' } = props;
  const marketProps = getMarketConfigByInputProps(props);
  const { dataLink } = marketProps;
  const apiCallData = Array.isArray(dataLink) ?
    dataLink.reduce((acc, item) => {
      acc[item] = inputs[`${formType.toUpperCase()}_${item}`];
      return acc;
    }, {})
    : inputs[`${formType.toUpperCase()}_${dataLink}`]
  const newProps = {
    ...marketProps,
    apiCallData,
  };
  return <InputType {...newProps} />;
}

function InputType(props) {
  const {
    dictionary,
    formType = 'account',
    type = 'text',
    labelRef,
    id: propsId,
    soId,
    stateRef,
    field,
    value: propsValue,
    onChange: propsOnChange = () => { },
    ...restOfProps
  } = props;

  const label =
    findInDictionary(dictionary, labelRef) ||
    findInDictionary(dictionary, propsId);

  const [inputs, set_inputs] = useRecoilState(inputState);

  const value = propsValue || inputs[stateRef || propsId];

  function onChange_AddToStore(id, value) {
    set_inputs({ ...inputs, [id]: value });
    propsOnChange(id, value);
  }

  const newProps = {
    ...restOfProps,
    label,
    id: stateRef || propsId,
    formType,
    dictionary,
    type,
    value,
    onChange: onChange_AddToStore,
    field,
    stateRef,
  };

  switch (type) {
    case 'switch':
      return <BinarySelector {...newProps} />;
    case 'dropdown':
      return <Dropdown {...newProps} />;
    default:
      return <TextInput {...newProps} />;
  }
}

function BinarySelector(props) {
  const { locale } = useRecoilValue(formState);
  const {
    isValid,
    onChange: propsOnChange,
    id,
    data: { list = [] } = {},
    ...rest
  } = props;

  const urls = localizedData(props.country, locale)
  const switchConfig = {
    TERMS: {
      links: [{ text: list[0], url: urls?.termsPDF }],
    },
    GAIYOSHOMEN: {
      links: [{ text: list[1], url: urls?.gaiyoshomenPDF }],
    },
    KR_PRIVACY_POLICY: {
      links: [{ text: list[2], url: urls?.privacyPDF }]
    },
    TH_PDPA: {
      links: [{ text: list[3], url: urls?.pdpaPDF }]
    }
  };
  function onChange(value) {
    isValid(value);
    propsOnChange(id, value);
  }
  const newProps = {
    ...rest,
    id,
    onChange,
    links: switchConfig?.[id]?.links,
  };
  return <BlueSwitch {...newProps} />;
}
