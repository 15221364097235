import {
  kanjiOnly,
  match,
  taiwanZip,
} from 'components/common/form/formValidationRules';
import { threeNonKanjiRegex } from 'helpers/regex';

const TW = {
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ZIP', 'STATE', 'CITY', 'ADDRESS1'],
        },
      },
    },
  },
  serviceObjects: {
    autoComplete: false,
  },
  market: 'TW',
  inputs: {
    NAME_FIRST: { validationChecks: [kanjiOnly()] },
    NAME_LAST: { validationChecks: [kanjiOnly()] },
    ADDRESS1: {
      validationChecks: [match(threeNonKanjiRegex, 'validation-3nonkanji')],
    },
    CITY: {
      type: 'dropdown',
      dataLink: ['ZIP', 'STATE'],
    },
    STATE: {
      labelRef: 'DISTRICT',
      type: 'dropdown',
      dataLink: 'ZIP',
    },
    ZIP: {
      mask: '999',
      labelRef: 'POSTAL_CODE',
      validationChecks: [taiwanZip()],
    },
  },
};
export default TW;
