import React from 'react';
import ErrorPage from './ErrorPage';
import { withTransaction } from "@elastic/apm-rum-react";
import { apm } from "@elastic/apm-rum";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: 'Server Error',
    };
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service - after render
    const { message = 'MISC.ERROR' } = error;
    this.setState({ ...this.state, errorMessage: message });
    console.error({ error, errorInfo });

    if (apm) {
      apm.setCustomContext({
        componentStack: errorInfo?.componentStack || "No component stack",
      });

      apm.captureError(error);
    }
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError) {
      console.log(`Rendering ErrorPage at ${window?.location?.href}: ${this.state?.errorMessage}`);
      
      switch (this.props.type) {
        case 'component':
          return <div>{this.state.errorMessage}</div>;
        default:
          return <ErrorPage errorMessage={this.state.errorMessage} />;
      }
    }

    return this.props.children;
  }
}

export default withTransaction("ErrorBoundary", "component")(ErrorBoundary);
